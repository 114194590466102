import React, { useState,useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Toast, ToastBody, ToastHeader
} from 'reactstrap';
import { useDispatch } from 'react-redux'; // Import useDispatch from react-redux
import { updateUserPassword } from '../store/Members/membersSlice'; // Import the updateUserPassword action
import ProfilePic from "../assets/profile/dummy.jpg";
const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;


const UpdatePasswordPopup = ({ isOpen, toggle, user }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      // Reset all fields when the modal is closed
      setPassword('');
      setConfirmPassword('');
      setPasswordStrength('');
      setPasswordMatch(true);
    }
  }, [isOpen]); // Triggered whenever the modal's open state changes

  const dispatch = useDispatch(); // Initialize the dispatcher

  const validatePassword = (password) => {
    let strength = '';
    if (password.length >= 8) strength = 'strong';
    else if (password.length >= 5) strength = 'medium';
    else strength = 'weak';
    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
    setPasswordMatch(value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordMatch(value === password);
  };

  const handleSubmit = () => {
    if (passwordMatch && passwordStrength === 'strong') {
      // Dispatch the updateUserPassword action with userId and newPassword
      dispatch(updateUserPassword({ userId: user._id, newPassword: password }))
        .then(() => {
          // Display toast notification on success
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
          toggle(); // Close modal
        })
        .catch((error) => {
          console.log("Failed to update password:", error);
        });
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Change Password</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <img src={user?.image ? `${BaseUrlProfile}/profile/${user?.image}` :  ProfilePic} alt="Profile" className="rounded-circle mb-3" width="100" />
            <h5>{user?.name}</h5>
            <p>{user?.email}</p>
          </div>
          <Form>
            <FormGroup>
              <Label for="newPassword">New Password</Label>
              <Input
                type="password"
                name="password"
                id="newPassword"
                placeholder="Enter new password"
                value={password}
                onChange={handlePasswordChange}
                invalid={passwordStrength === 'weak'}
              />
              <small>Password strength: {passwordStrength}</small>
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">Confirm Password</Label>
              <Input
                type="password"
                name="password"
                id="confirmPassword"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                invalid={!passwordMatch}
              />
              {!passwordMatch && <small className="text-danger">Passwords do not match</small>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={!passwordMatch || passwordStrength !== 'strong'}>
            Update Password
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
        <Toast isOpen={showToast}>
          <ToastHeader icon="success">Success</ToastHeader>
          <ToastBody>Password updated successfully!</ToastBody>
        </Toast>
      </div>
    </div>
  );
};

export default UpdatePasswordPopup;
