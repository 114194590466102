import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import Logo from "../../assets/logo/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
const Sidebar = () => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');

    const getActiveItem = () => {
        const path = location.pathname;
        console.log('path : ', path);

        // Determine active item based on the path
        if (path.includes('dashboard')) return 'dashboard';
        if (path.includes('members')) return 'members';
        if (path.includes('subscriptions')) return 'subscriptions';
        if (path.includes('transactions')) return 'transactions';
        if (path.includes('categories')) return 'categories';
        if (path.includes('notifications')) return 'notifications';
        if (path.includes('product')) return 'product';


        return '';
    };

    useEffect(() => {
        setActiveItem(getActiveItem());
    }, [location.pathname]);

    return (
        <nav className="navbar navbar-vertical navbar-expand-lg sidebar">

            <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                <div className="navbar-vertical-content">
                    <ul className="navbar-nav flex-column" id="navbarVerticalNav">
                        <li className="nav-item text-center">
                            <img src={Logo} alt="Logo" className="img-fluid mt-0 my-5 w-75" />
                        </li>
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 collapsed ${activeItem === 'dashboard' ? 'activeBtn' : ''} `} to="/dashboard" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-pie-chart">
                                                <path d="M21.21 15.89A10 10 0 1 1 8 2.83">
                                                </path>
                                                <path d="M22 12A10 10 0 0 0 12 2v10z">
                                                </path>
                                            </svg>
                                        </span>
                                        <span className="nav-link-text">Dashboard</span>
                                    </div>
                                </RouterNavLink>

                                {/* <div className="parent-wrapper label-1">
                                    <ul className="nav collapse parent"  id="nv-home">
                                        <li className="collapsed-nav-item-title d-none">Home</li>
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link" to="../../index.html">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-text">E commerce</span>
                                                </div>
                                            </RouterNavLink>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 collapsed ${activeItem === 'members' ? 'activeBtn' : ''} `} to="/members" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user me-2 text-body align-bottom">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2">
                                                </path>
                                                <circle cx="12" cy="7" r="4">
                                                </circle>
                                            </svg>
                                        </span>
                                        <span className="nav-link-text">Members</span>
                                    </div>
                                </RouterNavLink>
                            </div>
                        </li>
                        <li className={`subMenuList ${activeItem === 'members' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/members/timeTracker">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- Time Tracker</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 collapsed ${activeItem === 'product' ? 'activeBtn' : ''} `} to="/product/document-manage" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16px" height="16px"> <path d="M326.3 218.8c0 20.5-16.7 37.2-37.2 37.2h-70.3v-74.4h70.3c20.5 0 37.2 16.7 37.2 37.2zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-128.1-37.2c0-47.9-38.9-86.8-86.8-86.8H169.2v248h49.6v-74.4h70.3c47.9 0 86.8-38.9 86.8-86.8z"/></svg>
                                        </span>
                                        <span className="nav-link-text">Products</span>
                                    </div>
                                </RouterNavLink>
                            </div>
                        </li>
                        <li className={`subMenuList ${activeItem === 'product' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/product/document-manage">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- Manage Products</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className={`subMenuList ${activeItem === 'product' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/product/document-manage">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- Time Schedule</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className={`subMenuList ${activeItem === 'product' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/product/document-manage">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- Human Resource</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className={`subMenuList ${activeItem === 'product' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/product/document-manage">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- Document Management</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className={`subMenuList ${activeItem === 'product' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/product/document-manage">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- ATS</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className={`subMenuList ${activeItem === 'product' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/product/document-manage">
                                <div className="d-flex justify-content-start pl-44" >
                                    <span className="linkTest">- Signature</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        
                        
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 collapsed ${activeItem === 'subscriptions' ? 'activeBtn' : ''} `} to="/subscriptions" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 320 512"><path d="M160 0c17.7 0 32 14.3 32 32l0 35.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11l0 33.4c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-34.9c-.4-.1-.9-.1-1.3-.2l-.2 0s0 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7s0 0 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11L128 32c0-17.7 14.3-32 32-32z"/></svg>
                                        </span>
                                        <span className="nav-link-text">Subscriptions</span>
                                    </div>
                                </RouterNavLink>
                            </div>
                        </li>
                        <li className={`subMenuList ${activeItem === 'subscriptions' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/subscriptions/create">
                                <div className="d-flex justify-content-start pl-44" style={{    width: '198px'}}>
                                    <span className="linkTest">- Create Membership</span>
                                </div>
                            </RouterNavLink>
                        </li>
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 collapsed ${activeItem === 'categories' ? 'activeBtn' : ''} `} to="/categories" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 512 512"> <path d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z"/></svg>
                                        </span>
                                        <span className="nav-link-text">Categories</span>
                                    </div>
                                </RouterNavLink>
                            </div>
                        </li>
                        {/* <li className={`subMenuList ${activeItem === 'categories' ? '' : 'd-none'} `}>
                            <RouterNavLink className="linkToUrl " to="/categories/create">
                                <div className="d-flex justify-content-start pl-44" style={{    width: '198px'}}>
                                    <span className="linkTest">- Create Category</span>
                                </div>
                            </RouterNavLink>
                        </li> */}
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 ${activeItem === 'transactions' ? 'activeBtn' : ''} `} to="/transactions" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128L0 384c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64L64 64zm64 320l-64 0 0-64c35.3 0 64 28.7 64 64zM64 192l0-64 64 0c0 35.3-28.7 64-64 64zM448 384c0-35.3 28.7-64 64-64l0 64-64 0zm64-192c-35.3 0-64-28.7-64-64l64 0 0 64zM288 160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                                        </span>
                                        <span className="nav-link-text">Transactions</span>
                                    </div>
                                </RouterNavLink>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-item-wrapper">
                                <RouterNavLink className={`nav-link dropdown-indicator label-1 collapsed ${activeItem === 'notifications' ? 'activeBtn' : ''} `} to="/notifications" >
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown-indicator-icon-wrapper">
                                        </div>
                                        <span className="nav-link-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16px" height="16px"><path d="M224 0c-17.7 0-32 14.3-32 32l0 19.2C119 66 64 130.6 64 208l0 18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416l384 0c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8l0-18.8c0-77.4-55-142-128-156.8L256 32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
                                        </span>
                                        <span className="nav-link-text">Notifications</span>
                                    </div>
                                </RouterNavLink>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="navbar-vertical-footer">
                <button className="btn navbar-vertical-toggle border-0 fw-semibold w-100 white-space-nowrap d-flex align-items-center">
                    <span className="uil uil-left-arrow-to-left fs-8">
                    </span>
                    <span className="uil uil-arrow-from-right fs-8">
                    </span>
                    <span className="navbar-vertical-footer-text ms-2">Collapsed View</span>
                </button>
            </div>
        </nav>
    );
};

export default Sidebar;
