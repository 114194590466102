import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert
import { fetchCategoryById, updateCategory } from "../../store/Category/categoriesSlice"; // Adjust import paths as needed
import { showToast } from "../../Helper/alert";

const EditCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    status: "Active", // Default value
  });

  const { selectedCategory } = useSelector((state) => state.categories);

  useEffect(() => {
    // Fetch the category details
    dispatch(fetchCategoryById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedCategory) {
      setFormData({
        name: selectedCategory.name || "",
        status: selectedCategory.status || "Active",
      });
    }
  }, [selectedCategory]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const validateForm = () => {
    const { name, status } = formData;

    if (!name || !status) {
      showToast("Please fill in all required fields.", "error");
      return false;
    }

    if (!['Active', 'Inactive'].includes(status)) {
      showToast("Status must be 'Active' or 'Inactive'.", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await dispatch(updateCategory({ id, formData }));
      showToast("Category updated successfully!", "success");
      navigate("/categories"); // Redirecting to categories list
    } catch (error) {
      showToast("Failed to update category: " + error.message, "error");
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <div className="row rounded">
          <div className="col-md-12 table-container p-0 m-0">
            <h3 className="card-title text-left mb-4">Edit Category</h3>

            <div className="card">
              <div className="card-body p-5">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-md-9 col-sm-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <div className="col-md-12">
                          <label htmlFor="name" className="form-label custom-label">
                            Category Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter Category Name"
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="status" className="form-label custom-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="status"
                            value={formData.status}
                            onChange={handleChange}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>

                      <div className="text-center py-3">
                        <button type="submit" className="btn btn-success px-5">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategories;
