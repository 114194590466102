import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchSubscriptions } from "../../store/Transactions/transactionSlice";
import ProfilePic from "../../assets/profile/dummy.jpg";
import { setLoading } from "../../store/Loading/loadingSlice";

const RevenueTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;

  const { subscriptions, status, error } = useSelector(
    (state) => state.transaction
  );

  const statusClasses = {
    Active: "badge bg-success",
    "Past Due": "badge bg-warning",
    Expired: "badge bg-info",
  };

  const [openDropdown, setOpenDropdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const convertCentsToDollars = (cents) => (cents / 100).toFixed(2);
  const calculateTotals = () => {
    let totalPaymentReceived = 0;
    let totalUpcomingPayments = 0;
    let totalPastDue = 0;
    let totalRefund = 0;

    subscriptions.forEach((plan) => {
      const stripeData = JSON.parse(plan.json_response || "{}");
      const amount = stripeData.items?.data?.[0]?.price?.unit_amount || 0;

      // Assuming plan status determines if it's past due or upcoming
      if (plan.status === "Active") {
        totalPaymentReceived += amount;
      } else if (plan.status === "Past Due") {
        totalPastDue += amount;
      } else if (plan.status === "Expired") {
        totalRefund += amount;
      }
    });

    return {
      paymentReceived: convertCentsToDollars(totalPaymentReceived),
      upcomingPayments: convertCentsToDollars(totalUpcomingPayments),
      pastDue: convertCentsToDollars(totalPastDue),
      refund: convertCentsToDollars(totalRefund),
    };
  };

  const { paymentReceived, upcomingPayments, pastDue, refund } = calculateTotals();



  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(fetchSubscriptions());
    
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <div className="row rounded">
          <div className="col-md-12 p-0 m-0 ">
            <h4 className="card-title text-left mb-3">Revenue Transaction</h4>

            <div className="row mb-4">
              <div className="col-lg-3 col-md-6 mb-1">
                <div className="card border-bottom-0 rounded-0 border-top-green">
                  <div className="card-body text-left pb-1">
                    <h5 className="card-title">${paymentReceived}</h5>
                    <p className="card-text">Payment Received</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-1">
                <div className="card border-bottom-0 rounded-0 border-top-green">
                  <div className="card-body text-left pb-1">
                    <h5 className="card-title">${upcomingPayments}</h5>
                    <p className="card-text">Upcoming Payments (0)</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-1">
                <div className="card border-bottom-0 rounded-0 border-top-green">
                  <div className="card-body text-left pb-1">
                    <h5 className="card-title">${pastDue}</h5>
                    <p className="card-text">Past Due (0)</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-1">
                <div className="card border-bottom-0 rounded-0 border-top-green">
                  <div className="card-body text-left pb-1">
                    <h5 className="card-title">${refund}</h5>
                    <p className="card-text">Refund (0)</p>
                  </div>
                </div>
              </div>
            </div>

            <table className="custom-table w-full divide-y divide-gray-200 revenueTable">
              <thead>
                <tr className="">
                  <th className="px-4 py-2 cursor-pointer">Vendor Name</th>
                  <th className="px-4 py-2 cursor-pointer">Profile</th>
                  <th className="px-4 py-2 cursor-pointer">Membership Plan</th>
                  <th className="px-4 py-2 cursor-pointer">Start Date</th>
                  <th className="px-4 py-2 cursor-pointer">End Date</th>
                  <th className="px-4 py-2 cursor-pointer">Amount</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {status === "loading" && (
                  <tr>
                    <td className="px-4 py-2 text-center" colSpan="7">
                      Loading...
                    </td>
                  </tr>
                )}
                {status === "failed" && (
                  <tr>
                    <td className="px-4 py-2 text-center" colSpan="7">
                      Error: {error}
                    </td>
                  </tr>
                )}
                {subscriptions?.length ? (
                  subscriptions.map((plan, index) => {
                    const stripeData = JSON.parse(
                      plan.json_response || "{}"
                    );
                    console.log('stripeData : ' ,stripeData)
                    return (
                      <tr key={plan._id} className="hover:bg-gray-50  h-auto">
                        <td className="px-4 py-4 align-top ">
                          <h6 className="pHeading">
                            {plan.userId?.name || "N/A"}
                          </h6>
                        </td>
                        <td className="px-4 py-4 align-top ">
                          <img
                            src={
                              plan.userId?.image
                                ? `${BaseUrlProfile}/profile/${plan.userId?.image}`
                                : ProfilePic
                            }
                            alt="Avatar"
                            className="avatar"
                          />
                        </td>
                        <td className="px-4 py-4 align-top ">
                          <div>
                            <span>{plan.membershipId?.title || "N/A"}</span>
                          </div>
                          <span className={statusClasses[plan.status]}>
                            {plan.status}
                          </span>
                        </td>

                        <td className="px-4 py-4 align-top ">
                          <p className="mb-0">
                            <span>
                              {new Date(plan.startDate).toLocaleDateString()}
                            </span>
                          </p>
                        </td>
                        <td className="px-4 py-4 align-top ">
                          <p className="mb-0">
                            <span>
                              {new Date(plan.endDate).toLocaleDateString()}
                            </span>
                          </p>
                        </td>
                        <td className="px-4 py-4 align-top ">
                          <p className="mb-0">
                            <span>{stripeData ? `$${convertCentsToDollars(stripeData.items.data[0].price.unit_amount)}` : "N/A"}</span>
                          </p>
                        </td>
                        <td className="px-4 py-4 align-top  position-relative d-flex">
                          <button
                            className="actions-button px-4"
                            style={{ background: "#f7f7f7" }}
                            onClick={() => toggleDropdown(index)}
                          >
                            Actions{" "}
                            <svg
                              className="arrow-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                            >
                              <path d="M7 10l5 5 5-5z" />
                            </svg>
                          </button>

                          <div
                            className={`dropdown-menu rounded-0 ${
                              openDropdown === index ? "d-block" : " "
                            } actionDropDown`}
                            style={{ background: "#f7f7f7" }}
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <li
                                  onClick={() =>
                                    navigate(`/subscriptions/${plan._id}`)
                                  }
                                >
                                  Refund Amount
                                </li>
                                <li
                                  onClick={() => {
                                    toggleModal();
                                  }}
                                >
                                  Partial Refund
                                </li>
                                <li className="delete-account">
                                  Cancel Subscription
                                </li>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="px-4 py-2 text-center" colSpan="8">
                      <p>No Record Found.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Bootstrap Modal */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueTransaction;
