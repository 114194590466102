import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Toast, ToastBody, ToastHeader, Alert
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import ProfilePic from "../assets/profile/dummy.jpg";
import { deleteUserAccount } from '../store/Members/membersSlice';
const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;

const AccountDeletionModal = ({ isOpen, toggle, user }) => {
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      // Reset all fields when the modal is closed
      setDeleteConfirmed('');
      setEmailContent('');
    }
  }, [isOpen]); 

  const dispatch = useDispatch(); // Initialize the dispatcher

  const handleDeleteToggle = () => {
    setDeleteConfirmed(!deleteConfirmed);
  };

  const handleEmailChange = (e) => {
    setEmailContent(e.target.value);
  };

  const handleSubmit = () => {
    if (deleteConfirmed && emailContent) {
      // Dispatch the deleteAccount action with userId and emailContent
      dispatch(deleteUserAccount({ userId: user._id, emailContent }))
        .then(() => {
          // Display toast notification on success
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
          toggle(); // Close modal
        })
        .catch((error) => {
          console.log("Failed to delete account:", error);
        });
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Account</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <img src={user?.image ? `${BaseUrlProfile}/profile/${user?.image}` :  ProfilePic} alt="Profile" className="rounded-circle mb-3" width="100" />
            <h5>{user?.name}</h5>
            <p>{user?.email}</p>
          </div>
          <Form>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={deleteConfirmed} onChange={handleDeleteToggle} />
                I confirm that I want to delete this account
              </Label>
            </FormGroup>
            {deleteConfirmed && (
              <Alert color="danger" className="mt-3">
                Deleting this account is permanent and cannot be undone.
              </Alert>
            )}
            <FormGroup className="mt-3">
              <Label for="emailContent">Email Content to Send</Label>
              <Input
                type="textarea"
                name="emailContent"
                id="emailContent"
                placeholder="Write your email content here..."
                value={emailContent}
                onChange={handleEmailChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit} disabled={!deleteConfirmed || !emailContent}>
            Delete Account
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
        <Toast isOpen={showToast}>
          <ToastHeader icon="danger">Account Deleted</ToastHeader>
          <ToastBody>The account has been successfully deleted, and an email has been sent.</ToastBody>
        </Toast>
      </div>
    </div>
  );
};

export default AccountDeletionModal;
