// src/redux/userNotificationsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setLoading } from '../Loading/loadingSlice';
const BaseUrl = process.env.REACT_APP_BASH_URL;




export const notificationList = createAsyncThunk(
    'notification/notificationList',
    async (empId, thunkAPI) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            console.log('token : ' , token)
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.get(`${BaseUrl}/admin/notifications/list`,{ headers: options });
            thunkAPI.dispatch(setLoading(false))
            if (!response.data.success) {
                throw new Error('Network response was not ok');
            }
            return response.data.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const markAllAsRead = createAsyncThunk(
    'notification/markAllAsRead',
    async (empId, thunkAPI) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            console.log('token : ' , token)
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.get(`${BaseUrl}/admin/notifications/markAllAsRead`, { headers: options });
            if (!response.data.success) {
                throw new Error('Network response was not ok');
            }
            return response.data.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getUnreadCount = createAsyncThunk(
    'notification/getUnreadCount',
    async (empId, thunkAPI) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            console.log('token : ' , token)
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.get(`${BaseUrl}/admin/notifications/unreadCount`, { headers: options });
            if (!response.data.success) {
                throw new Error('Network response was not ok');
            }
            return response.data.unReadNotifications;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialState = {
    notifications: [],
    unreadCount: 0,
    status: 'idle',
    error: null,
};

const userNotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(notificationList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(notificationList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.notifications = action.payload;
            })
            .addCase(notificationList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(markAllAsRead.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(markAllAsRead.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(markAllAsRead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getUnreadCount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUnreadCount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.unreadCount = action.payload;
            })
            .addCase(getUnreadCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default userNotificationsSlice.reducer;
