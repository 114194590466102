// src/redux/slices/authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BaseUrl = process.env.REACT_APP_BASH_URL;

const initialState = {
    user: null,
    token: null,
    userId: null,
    isAuthenticated: false,
    loading: false,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthUser: (state, action) => {
            state.user = action.payload.result;
            state.token = action.payload.token;
            state.userId = action.payload.result._id;
            state.isAuthenticated = true;
        },
        logoutUser: (state) => {
            state.user = null;
            state.token = null;
            state.userId = null;
            state.isAuthenticated = false;
        },
        setUserProfile: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { setAuthUser, logoutUser, setUserProfile, setLoading, setError } = authSlice.actions;

export const fetchUserProfile = createAsyncThunk(
    'auth/fetchUserProfile',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(setLoading(true));
            const token = localStorage.getItem("admin-auth-token");

            const { auth } = getState();
            const response = await axios.get(`${BaseUrl}/admin/profile`, { headers: { Authorization: `Bearer ${token}` } });
            dispatch(setUserProfile(response.data));
        } catch (error) {
            dispatch(setError(error.message));
            return rejectWithValue(error.message);
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const updateUserProfile = createAsyncThunk(
    'auth/updateUserProfile',
    async (profileData, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(setLoading(true));
            const { auth } = getState();
            const token = localStorage.getItem("admin-auth-token");
            const response = await axios.put(`${BaseUrl}/admin/profile/update`, profileData, { headers: { Authorization: `Bearer ${token}` } });
            dispatch(setUserProfile(response.data));
        } catch (error) {
            dispatch(setError(error.message));
            return rejectWithValue(error.message);
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async ({ email, password }, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post(`${BaseUrl}/admin/login-super-admin`, { email, password });
            localStorage.setItem('admin-auth-token', response.data.token);
            dispatch(setAuthUser(response.data));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const logout = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    dispatch(logoutUser());
};

export default authSlice.reducer;
