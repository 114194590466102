import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchMembershipPlans,
  setSortBy,
  setSortOrder,
  setCurrentPage,
  deleteMembershipPlan,
} from "../../store/Membership/membershipSlice";
import DescriptionPopup from "../../popups/descriptionPopup";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    membershipPlans,
    sortBy,
    sortOrder,
    currentPage,
    itemsPerPage,
  } = useSelector((state) => state.memberships);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(""); // State to hold selected description
  const [showModal, setShowModal] = useState(false);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [planDescription, setPlanDescription] = useState("");

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    dispatch(fetchMembershipPlans());
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const handleSort = (column) => {
    dispatch(setSortBy(column));
    dispatch(setSortOrder(sortOrder === "asc" ? "desc" : "asc"));
  };

  const handleDelete = (planId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(deleteMembershipPlan(planId)).unwrap();
          Swal.fire(
            "Deleted!",
            "The membership plan has been deleted.",
            "success"
          );
        } catch (error) {
          Swal.fire("Error!", "Failed to delete the membership plan.", "error");
        }
      }
    });
  };

  const handleViewDescription = (description) => {
    setSelectedDescription(description);
    setShowModal(true);
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <div className="row rounded">
          <div className="col-md-12 p-0 m-0 ">
            <h4 className="card-title text-left mb-4">
              Membership Plans({membershipPlans?.length ?? 0})
            </h4>

            <table className="custom-table w-full divide-y divide-gray-200 subscriptionTable">
              <thead>
                <tr className="">
                  <th
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    Plan Details
                  </th>
                  <th
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    Members
                  </th>
                  <th
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    Pricing
                  </th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {membershipPlans?.length ? (
                  membershipPlans?.map((plan, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-4 py-4 align-top ">
                        <h5 className="pHeading">{plan.type}</h5>
                        <p className="mb-0">
                          Membership Plan ID : <span>{plan._id}</span>
                        </p>
                        <p className="mb-0">{plan.title}</p>
                      </td>
                      <td className="px-4 py-4 align-top ">
                        <div className="membersCount">
                          <span>{plan.activeSubscriberCount}</span>
                        </div>
                      </td>
                      <td className="px-4 py-4 align-top ">
                        <div className="defaultBtn pricingDiv">
                          <span>30 Days Free Trial</span>
                        </div>
                        <p className="mb-0">
                          Monthly: $<span>{parseFloat(plan.monthlyPrice.toFixed(2))}</span>
                        </p>
                        <p className="mb-0">
                          Annually: $<span>{plan.yearlyPrice}</span>
                        </p>
                      </td>
                      <td className="px-4 py-4 align-top  position-relative d-flex">
                        <button
                          className="actions-button px-4"
                          style={{ background: "#f7f7f7" }}
                          onClick={() => toggleDropdown(index)}
                        >
                          Actions{" "}
                          <svg
                            className="arrow-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7 10l5 5 5-5z" />
                          </svg>
                        </button>

                        <div
                          className={`dropdown-menu rounded-0 ${
                            openDropdown === index ? "d-block" : " "
                          } actionDropDown`}
                          style={{ background: "#f7f7f7" }}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <li
                                onClick={() =>
                                  navigate(`/subscriptions/${plan._id}`)
                                }
                              >
                                Edit Plan
                              </li>
                              <li
                                onClick={() => {
                                  setPlanDescription(plan.description); // Set description from CKEditor
                                  toggleModal();
                                }}
                              >
                                View Feature
                              </li>
                              <li
                                className="delete-account"
                                onClick={() => handleDelete(plan._id)}
                              >
                                Delete Account
                              </li>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-4 py-2 text-center" colSpan="4">
                      <p>No Record Found.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Bootstrap Modal */}
          </div>
        </div>
      </div>
      <DescriptionPopup
        isOpen={isModalOpen}
        toggle={toggleModal}
        description={planDescription}
      />
    </div>
  );
};

export default Index;
