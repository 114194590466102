// src/components/Layout.js

import React from 'react';
import Header from './header';   // Import Header component
import Footer from './Footer';   // Import Footer component
import Sidebar from './sidebar'; // Import Sidebar component
import Loader from './../../Components/loader';   // Import Loader component
import { useSelector } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import "./../../css/theme.min.css";

import "./../../css/custom.css";
const AuthenticatedLayout = ({ children }) => {
  const loading = useSelector((state) => state.loading);

  return (
    <>

      <Sidebar />
      <Header />

      <div className="content">
        {loading && <Loader />}
        {children}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AuthenticatedLayout;
