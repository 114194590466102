import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DescriptionPopup = ({ isOpen, toggle, description }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Plan Features</ModalHeader>
      <ModalBody>
        {/* Render the description safely */}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DescriptionPopup;
