import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput, Paper, Snackbar } from "@mui/material";
import { IoEye, IoEyeOff } from "react-icons/io5";
import * as EmailValidator from 'email-validator';
import LoadingButton from '@mui/lab/LoadingButton';
import Logo from "../assets/logo/logo.png";
import { useDispatch } from 'react-redux';
import { login } from "../store/Auth/authSlice";

const defaultTheme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
      Shiftnpay, LLC,  a Computerlog Company
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;
console.log('bashUrl : ', bashUrl);

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [circul, setCircul] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [customVariant, setCustomVariant] = React.useState("success");
  const [error, setError] = React.useState("");
  const [emp, setEmp] = React.useState({
    email: "",
    password: "",
  });

  const [valErr, setValErr] = React.useState({
    email: "",
    password: "",
  });

  // handle close for UI component alert
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // input onchange event
  const handleOnChange = (evt) => {
    setEmp({
      ...emp,
      [evt.target.name]: evt.target.value,
    });

    setValErr({
      email: "",
      password: "",
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!emp.email) {
      setValErr({ email: "Email required!" });
    } else if (!EmailValidator.validate(emp.email)) {
      setValErr({ email: "Invalid email!" });
    } else if (!emp.password) {
      setValErr({ password: "Password required!" });
    } else if (emp.password.length < 5) {
      setValErr({ password: "Password must be more than 5 characters!" });
    } else {
      setCircul(true);
      
      try {
        // Dispatch the login action
        await dispatch(login(emp));

        // Navigate to the dashboard on successful login
        navigate("/dashboard");
      } catch (err) {
        setError(err?.message || 'Login failed');
        setCustomVariant("error");
        setOpen(true);
      } finally {
        setCircul(false);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
        <Alert onClose={handleClose} severity={customVariant} sx={{ width: "100%" }}>{error ? error : ""}</Alert>
      </Snackbar>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} sx={{ backgroundImage: 'url(https://shiftnpay.com/api/images/login/regisrationImage.png)', backgroundRepeat: 'no-repeat', backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900], backgroundSize: 'cover', backgroundPosition: 'top', }} />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Box sx={{ width: 200, height: 150 }}><img src={Logo} alt='logo' style={{ width: '100%', height: '100%' }} /></Box>
            <Typography component="h1" variant="h5"> Admin Login</Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField error={valErr.email ? true : false} helperText={valErr.email ? valErr.email : ""} required fullWidth id="email" onChange={handleOnChange} label="Email" name="email" type={"email"} sx={{ mb: 2 }} />

              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput name="password" value={emp.password} onChange={handleOnChange} id="outlined-adornment-password" type={showPassword ? "text" : "password"} error={valErr.password ? true : false} endAdornment={
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                    {showPassword ? <IoEyeOff /> : <IoEye />}
                  </IconButton>} label="Password" />
                <Typography variant="caption" color={'error'} >{valErr.password ? valErr.password : ''}</Typography>
              </FormControl>

              {circul ? (
                <LoadingButton loading fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Sign In
                </LoadingButton>
              ) : (
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: "#108A00", '&:hover': { bgcolor: "#0D6E00" } }}>
                  Sign In
                </Button>
              )}

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
