import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BaseUrl = process.env.REACT_APP_BASH_URL;

const options = {
    Authorization: `Bearer ${localStorage.getItem("admin-auth-token")}`,
    "Content-Type": "application/json",
  };

// Async actions
export const fetchDocuments = createAsyncThunk('documents/fetchDocuments', async (params, { rejectWithValue }) => {
  try {
    const { search, date } = params;
    const response = await axios.get(`${BaseUrl}/admin/product/documents?search=${search ?? ''}&date=${date ?? ''}` ,{ headers: options });
    
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const uploadDocument = createAsyncThunk('documents/uploadDocument', async (formData, { rejectWithValue ,dispatch }) => {
  try {
    const response = await axios.post(`${BaseUrl}/admin/product/documents/upload`, formData ,{ headers:  {
        Authorization: `Bearer ${localStorage.getItem("admin-auth-token")}`,
        'Content-Type': 'multipart/form-data',
      } });
      dispatch(fetchDocuments({}))
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteDocument = createAsyncThunk('documents/deleteDocument', async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`${BaseUrl}/admin/product/documents/${id}` , { headers: options });
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Slice
const documentSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Fetch documents
    builder.addCase(fetchDocuments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.documents = action.payload;
    });
    builder.addCase(fetchDocuments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // Upload document
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      state.documents.push(action.payload);
    });
    // Delete document
    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      state.documents = state.documents.filter(file => file._id !== action.payload);
    });
  },
});

export default documentSlice.reducer;
