import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ProfilePic from "../../assets/profile/dummy.jpg";
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom'; // Import NavLink from react-router-dom
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile } from '../../store/Auth/authSlice';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en-gb';
import { getUnreadCount, markAllAsRead, notificationList } from '../../store/Notification/userNotificationsSlice';


dayjs.extend(relativeTime);
dayjs.locale('en-gb');

const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;

const Header = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { user, loading, error } = useSelector((state) => state.auth);
    const notifications = useSelector((state) => state.notifications.notifications);
    const notificationCount = useSelector((state) => state.notifications.unreadCount);
    useEffect(() => {
        if (!user) {
            console.log('profile not found..')
            dispatch(fetchUserProfile());
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUnreadCount());
    }, [location.pathname]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleProfileClick = () => {
        navigate('/profile');
        setDropdownOpen(false);
    };
    const loadNotfications = () => {
        dispatch(notificationList());
    }
    const markAllRead = () => {
        dispatch(markAllAsRead());
        dispatch(notificationList());
        dispatch(getUnreadCount());
    }

    const handleLogout = () => {
        localStorage.removeItem('admin-auth-token');
        navigate('/login');
    };
    const WebsiteRedirect = () => {
        window.open('https://shiftnpay.com', '_blank');
    };
    return (
        <nav className="navbar navbar-top fixed-top navbar-expand" id="navbarDefault" style={{ paddingRight: '2.5rem' }}>
            <div className="collapse navbar-collapse justify-content-between">
                <div className="navbar-logo">

                    <RouterNavLink className="navbar-brand me-1 me-sm-3" to="#">
                        <div className="d-flex align-items-center invisible">
                            <div className="d-flex align-items-center">
                                <img src="../../assets/img/icons/logo.png" alt="phoenix" width="27" />
                                <h5 className="logo-text ms-2 d-none d-sm-block">phoenix</h5>
                            </div>
                        </div>
                    </RouterNavLink>
                    <button className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
                        <span className="navbar-toggle-icon">
                            <span className="toggle-line">
                            </span>
                        </span>
                    </button>
                </div>
                <div className='visit-website-btn py-2 px-3 cursor-pointer' onClick={WebsiteRedirect} style={{ maxWidth: '130px', position: 'absolute', right: '174px' }}>
                    <div>
                        <span className='px-2 font-weight-bold'>Visit Website</span>
                    </div>
                </div>
                <ul className="navbar-nav navbar-nav-icons flex-row">
                    <li className="nav-item dropdown">
                        <a className="nav-link show" href="#" style={{ minWidth: "3.00rem" }} role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-bs-auto-close="outside" onClick={() => {
                            loadNotfications();
                        }}>
                            <span className="d-block" style={{ height: "20px", width: "20px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell" style={{ height: "20px", width: "20px" }}>
                                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9">
                                    </path>
                                    <path d="M13.73 21a2 2 0 0 1-3.46 0">
                                    </path>
                                </svg>
                            </span>
                            {notificationCount > 0 && (
                                <span className="notificationCount">
                                    {notificationCount}
                                </span>
                            )}
                        </a>
                        <div className="dropdown-menu dropdown-menu-end notification-dropdown-menu py-0 shadow border navbar-dropdown-caret" id="navbarDropdownNotfication" aria-labelledby="navbarDropdownNotfication" data-bs-popper="static">
                            <div className="card position-relative border-0">
                                <div className="card-header p-2">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-body-emphasis mb-0">Notifications</h5>
                                        <button className="btn btn-link p-0 fs-9 fw-normal" type="button" onClick={()=>{
                                            markAllRead();
                                        }}>Mark all as read</button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="scrollbar-overlay simplebar-scrollable-y" style={{ height: "20rem" }} data-simplebar="init">
                                        {notifications.length > 0 ? (
                                            <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                                                <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer">
                                                    </div>
                                                </div>
                                                <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                                        <div className="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{ height: "100%", overflow: "hidden scroll" }}>
                                                            <div className="simplebar-content notification" style={{ padding: "0px" }}>
                                                                {notifications.map((notification) => (
                                                                    <RouterNavLink to={notification?.data?.navigateTo ? notification?.data?.navigateTo : '#'}>
                                                                    <div className={`px-2 px-sm-3 py-3 notification-card position-relative ${notification.readStatus ==true ? 'read' : 'unread '} border-bottom`}>
                                                                        <div className="d-flex align-items-center justify-content-between position-relative">
                                                                            <div className="d-flex">
                                                                                <div className="avatar avatar-m status-online me-3 bellIconInList">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"  ><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                                                                </div>
                                                                                <div className="flex-1 me-sm-3">
                                                                                    <h4 className="fs-9 text-body-emphasis mb-1">{notification?.title}</h4>
                                                                                    <p className="fs-9 text-body-highlight mb-0 fw-normal">
                                                                                        <span className="me-1 fs-10"></span>{notification?.body}
                                                                                    </p>
                                                                                    <p className="text-body-secondary fs-9 mb-2">
                                                                                        <svg className="svg-inline--fa fa-clock me-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                                                            <path fill="currentColor" d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                                                                            </path>
                                                                                        </svg>
                                                                                        <span className="fw-bold">{dayjs(notification.sentAt).fromNow()} </span></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dropdown notification-dropdown">
                                                                                {/* <button className="btn fs-10 btn-sm dropdown-toggle dropdown-caret-none transition-none" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent">
                                                                                    <svg className="svg-inline--fa fa-ellipsis fs-10 text-body" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                                                                        <path fill="currentColor" d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z">
                                                                                        </path>
                                                                                    </svg>
                                                                                </button> */}
                                                                                <div className="dropdown-menu py-1">
                                                                                    <a className="dropdown-item" href="#!" 
                                                                                    style={{fontSize: "11px"}}>Mark as unread</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </RouterNavLink>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="simplebar-placeholder" style={{ "width": "358px", height: "0px" }}>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-center text-gray-600 mt-8" style={{ "width": "358px", height: "0px" }}>
                                                <p className="text-lg">No notifications found</p>
                                            </div>
                                        )}

                                        {/* <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
                                            <div className="simplebar-scrollbar" style={{ height: "300px", display: "block", transform: "translate3d(0px, 0px, 0px)" }}>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="card-footer p-0 border-top border-translucent border-0">
                                    <div className="my-2 text-center fw-bold fs-10 text-body-tertiary text-opactity-85">
                                        <a className="fw-bolder" href="../pages/notifications.html">Notification history</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <RouterNavLink className="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar avatar-l ">
                                <img className="rounded-circle " src={user?.image ? `${BaseUrlProfile}/profile/${user?.image}` : ProfilePic} alt="" />
                            </div>
                        </RouterNavLink>
                        <div className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border" aria-labelledby="navbarDropdownUser">
                            <div className="card position-relative border-0">
                                <div className="card-body p-0">
                                    <div className="text-center pt-4 pb-3">
                                        <div className="avatar avatar-xl ">
                                            <img className="rounded-circle " src={user?.image ? `${BaseUrlProfile}/profile/${user?.image}` : ProfilePic} alt="" />
                                        </div>
                                        <h6 className="mt-2 text-body-emphasis">{user?.firstName ? user?.firstName + ' ' + user?.lastName : 'Shiftnpay Admin'}</h6>
                                    </div>
                                </div>
                                <div className="overflow-auto scrollbar" style={{ height: '10rem' }}>
                                    <ul className="nav d-flex flex-column mb-2 pb-1">
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link px-3 d-block text-dark" to="/profile"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user me-2 text-body align-bottom">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2">
                                                </path>
                                                <circle cx="12" cy="7" r="4">
                                                </circle>
                                            </svg>
                                                <span>Profile</span>
                                            </RouterNavLink>
                                        </li>
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link px-3 d-block text-dark" to="/dashboard">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-pie-chart me-2 text-body align-bottom">
                                                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83">
                                                    </path>
                                                    <path d="M22 12A10 10 0 0 0 12 2v10z">
                                                    </path>
                                                </svg>Dashboard</RouterNavLink>
                                        </li>
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link px-3 d-block" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-lock me-2 text-body align-bottom">
                                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2">
                                                </rect>
                                                <path d="M7 11V7a5 5 0 0 1 10 0v4">
                                                </path>
                                            </svg>Posts &amp; Activity</RouterNavLink>
                                        </li>
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link px-3 d-block" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-settings me-2 text-body align-bottom">
                                                <circle cx="12" cy="12" r="3">
                                                </circle>
                                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                                                </path>
                                            </svg>Settings &amp; Privacy </RouterNavLink>
                                        </li>
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link px-3 d-block" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-help-circle me-2 text-body align-bottom">
                                                <circle cx="12" cy="12" r="10">
                                                </circle>
                                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3">
                                                </path>
                                                <line x1="12" y1="17" x2="12.01" y2="17">
                                                </line>
                                            </svg>Help Center</RouterNavLink>
                                        </li>
                                        <li className="nav-item">
                                            <RouterNavLink className="nav-link px-3 d-block" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-globe me-2 text-body align-bottom">
                                                <circle cx="12" cy="12" r="10">
                                                </circle>
                                                <line x1="2" y1="12" x2="22" y2="12">
                                                </line>
                                                <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                </path>
                                            </svg>Language</RouterNavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer p-0 pt-4 border-top border-translucent">
                                    <div className="px-3" onClick={handleLogout}> <RouterNavLink className="btn btn-phoenix-secondary d-flex flex-center w-100" href="#!"> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-log-out me-2">
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4">
                                        </path>
                                        <polyline points="16 17 21 12 16 7">
                                        </polyline>
                                        <line x1="21" y1="12" x2="9" y2="12">
                                        </line>
                                    </svg>Sign out</RouterNavLink>
                                    </div>
                                    <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
                                        <RouterNavLink className="text-body-quaternary me-1" href="#!">Privacy policy</RouterNavLink>•<RouterNavLink className="text-body-quaternary mx-1" href="#!">Terms</RouterNavLink>•<RouterNavLink className="text-body-quaternary ms-1" href="#!">Cookies</RouterNavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;
