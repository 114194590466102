import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert
import { fetchMembershipPlanById, getMembershipPlan, updateMembershipPlan } from "../../store/Membership/membershipSlice";
import { showToast } from "../../Helper/alert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Edit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    title: "",
    price: "",
    type: "Gold", // Default value
    paymentTerm: "Monthly", // Default value
    planDate: "",
    description: "",
  });

  const {selectedMembershipPlan} = useSelector((state) => state.memberships);

  useEffect(() => {
    // Fetch the membership plan details
    dispatch(fetchMembershipPlanById(id));
  }, [id]);

  useEffect(() => {
    console.log('selectedMembershipPlan : ' , selectedMembershipPlan)
    if (selectedMembershipPlan) {
      const formattedDate = selectedMembershipPlan.planDate
      ? new Date(selectedMembershipPlan.planDate).toISOString().split('T')[0]
      : "";

      setFormData({
      title: selectedMembershipPlan.title || "",
      price: selectedMembershipPlan.price || "",
      type: selectedMembershipPlan.type || "Gold",
      paymentTerm: selectedMembershipPlan.paymentTerm || "Monthly",
      planDate: formattedDate, // Set the formatted date
      description: selectedMembershipPlan.description || "",
    });
    }
  }, [selectedMembershipPlan]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevState) => ({
      ...prevState,
      description: data,
    }));
  };
  const validateForm = () => {
    const { title, price, planDate } = formData;

    if (!title || !price || !planDate) {
      showToast("Please fill in all required fields." , "error");
      return false;
    }

    if (!Number.isInteger(Number(price))) {
      showToast("Price should be an integer value.", "error");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
        console.log("Dispatching updateMembershipPlan with:", { id, formData });
        dispatch(updateMembershipPlan({ id, formData }));
        showToast("plan updated successfully!", "success");
        navigate("/subscriptions"); // Redirecting to memberships list
    } catch (error) {
      showToast("Failed to update membership: " + error.message, "error");
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <div className="row rounded">
          <div className="col-md-12 table-container p-0 m-0">
            <h3 className="card-title text-left mb-4">Edit Membership</h3>

            <div className="card">
              <div className="card-body p-5">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-md-9 col-sm-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <label htmlFor="title" className="form-label custom-label">
                            Membership Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="Enter Membership Title"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="price" className="form-label custom-label">
                            Price
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input
                              type="text"
                              className="form-control"
                              id="price"
                              value={formData.price}
                              onChange={handleChange}
                              placeholder="Enter Price"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <label htmlFor="type" className="form-label custom-label">
                            Membership Type
                          </label>
                          <select
                            className="form-select"
                            id="type"
                            value={formData.type}
                            onChange={handleChange}
                          >
                            <option value="Gold">Gold</option>
                            <option value="Silver">Silver</option>
                            <option value="Bronze">Bronze</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="paymentTerm" className="form-label custom-label">
                            Preferred Payment Term
                          </label>
                          <select
                            className="form-select"
                            id="paymentTerm"
                            value={formData.paymentTerm}
                            onChange={handleChange}
                          >
                            <option value="Monthly">Monthly (Bill every Month)</option>
                            <option value="Quarterly">Quarterly (Bill every 3 Months)</option>
                            <option value="Annually">Annually (Bill every Year)</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-12">
                          <label htmlFor="planDate" className="form-label custom-label">
                            Date to Make Plan Live
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="planDate"
                            value={formData.planDate}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12 pt-2">
                          <label htmlFor="description" className="form-label custom-label">
                            Description
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData?.description}
                            onChange={handleDescriptionChange}
                            config={{
                              minHeight: 300,
                              removePlugins: ['Image', 'MediaEmbed', 'EasyImage', 'CKFinder', 'ImageUpload'], // Ensure all image-related plugins are removed
                              toolbar: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'link',
                                'bulletedList',
                                'numberedList',
                                'blockQuote'
                              ],
                            }}
                          />
                        </div>
                      </div>

                      <div className="text-center py-3">
                        <button type="submit" className="btn btn-success px-5">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
