import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { dashboardData } from '../Dashboard/dashboardSlice';
import { setLoading } from '../Loading/loadingSlice';
const BaseUrl = process.env.REACT_APP_BASH_URL;
const token = localStorage.getItem("admin-auth-token"); 

// Fetch members list
export const membersList = createAsyncThunk(
  'members/list',
  async (_, {rejectWithValue ,dispatch }) => {
    try {
      dispatch(setLoading(true)); 
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.get(`${BaseUrl}/admin/members/list`, options);
      return response.data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// Update user status
export const updateUserStatus = createAsyncThunk(
  'members/updateStatus',
  async ({ userId, newStatus }, { dispatch }) => {
    try { 
      const options = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      await axios.put(`${BaseUrl}/admin/vendor-approval/${userId}`, { status: newStatus }, { headers: options });
      // After updating status, refetch the members list
      dispatch(membersList());
    } catch (error) {
      console.log(error);
      throw error; // Propagate the error for handling
    }
  }
);

// Update user password
export const updateUserPassword = createAsyncThunk(
  'members/updatePassword',
  async ({ userId, newPassword }, { dispatch, rejectWithValue }) => {
    try { 
      const options = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      await axios.put(`${BaseUrl}/admin/members/password/${userId}`, { password: newPassword }, { headers: options });
      // Optionally refetch the members list or take another action
      dispatch(membersList());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
export const updateUserProfile = createAsyncThunk(
  'members/updateProfile',
  async ({ userId, updatedProfile }, { dispatch, rejectWithValue }) => {
    try { 
      
      if (!token) {
        throw new Error('No authentication token found');
      }

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      await axios.put(
        `${BaseUrl}/admin/members/profile/${userId}`,
        updatedProfile,
        options
      );

      dispatch(membersList());

    } catch (error) {
      console.error('Error updating profile:', error.message);

      return rejectWithValue(error.message);
    }
  }
);


export const registerMember = createAsyncThunk(
  'members/registerMember',
  async (formData, { dispatch, rejectWithValue }) => {
    try {  
      const options = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      await axios.post(`${BaseUrl}/vendor/register-from-admin`, formData, { headers: options });
      dispatch(dashboardData())
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

// Delete user account
export const deleteUserAccount = createAsyncThunk(
  'members/deleteAccount',
  async ({ userId, emailContent }, { dispatch, rejectWithValue }) => {
    try {
     
      const options = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      await axios.post(`${BaseUrl}/admin/members/${userId}/delete`,
        { emailContent }, // Sending email content in the request body
        { headers: options }
      );
      // After deletion, refetch the members list
      dispatch(membersList());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchEmployeesByVendor = createAsyncThunk('employees/fetchByVendor', async (vendorId, { rejectWithValue }) => {
  try {
    const options = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(`${BaseUrl}/admin/members/employees/${vendorId}`,{ headers: options });
    return response.data.employees; // Assuming employees are returned in `employees` field
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.msg); // Error message from server
    } else {
      return rejectWithValue('An error occurred while fetching employees');
    }
  }
});


export const fetchEmployeeAssignedJobs = createAsyncThunk('employees/fetchAssignedJobsToEmployee', async ({vendor,employee}, { rejectWithValue }) => {
  try {
    const options = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(`${BaseUrl}/admin/members/employees/jobs/${vendor}/${employee}`,{ headers: options });
    return response.data.result; // Assuming employees are returned in `employees` field
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.msg); // Error message from server
    } else {
      return rejectWithValue('An error occurred while fetching employees');
    }
  }
});


const membersSlice = createSlice({
  name: 'members',
  initialState: {
    list: [],
    activeMemberslist: [],
    employees: [],
    assignedJobsToEmployee:[],
    status: 'idle',
    error: null,
    sortBy: 'name',
    sortOrder: 'asc',
    currentPage: 1,
    itemsPerPage: 10,
  },
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(membersList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(membersList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload.result;
        state.activeMemberslist = action.payload.result.filter((member) => member.status != false)
      })
      .addCase(membersList.rejected, (state, action) => {
        state.status = 'failed';
        console.log('failed');
        state.error = action.payload;
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchEmployeesByVendor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeesByVendor.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload;  
      })
      .addCase(fetchEmployeesByVendor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
        state.employees = []; 
        state.assignedJobsToEmployee = [];
      })
      .addCase(fetchEmployeeAssignedJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeAssignedJobs.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
        state.assignedJobsToEmployee = action.payload;  
      })
      .addCase(fetchEmployeeAssignedJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
        state.employees = []; 
      });
  },
});

export const { setSortBy, setSortOrder, setCurrentPage } = membersSlice.actions;
export default membersSlice.reducer;
