import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import ProfilePic from "../assets/profile/dummy.jpg"; // Path to default profile image
import { updateUserProfile } from '../store/Members/membersSlice';
import { useDispatch } from 'react-redux';
const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;

const QuickEditsPopup = ({ isOpen, toggle, user }) => {
  // Initialize form state with user data or default values
  const dispatch = useDispatch();

  const [email, setEmail] = useState(user?.email || '');
  const [mobile, setMobile] = useState(user?.profile?.mobile || '');
  const [address, setAddress] = useState(user?.profile?.address || '');
  const [restaurantsName, setRestaurantsName] = useState(user?.profile?.restaurantsName || '');
  const [description, setDescription] = useState(user?.profile?.description || '');
  const [contact, setContact] = useState(user?.profile?.contact || '');
  const [pinCode, setPinCode] = useState(user?.profile?.pinCode || '');
  const [industry, setIndustry] = useState(user?.profile?.industry || '');
  const [webUrl, setWebUrl] = useState(user?.profile?.webUrl || '');
  const [empNumber, setEmpNumber] = useState(user?.profile?.empNumber || '');
  const [location, setLocation] = useState(user?.profile?.location || '');
  const [year, setYear] = useState(user?.profile?.year || '');

  const handleSubmit = () => {
    const updatedProfile = {
      email,
      profile: {
        mobile,
        address,
        pinCode,
        restaurantsName,
        description,
        industry,
        webUrl,
        contact,
        empNumber,
        location,
      },
    };

    // Dispatch the updateUserProfile action with userId and updatedProfile
    dispatch(updateUserProfile({ userId: user._id, updatedProfile }))
      .then(() => {
        toggle(); // Close modal on success
      })
      .catch((error) => {
        console.error('Failed to update profile:', error);
      });
  };

  return (
    <Modal show={isOpen} onHide={toggle} centered className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Quick Edits</Modal.Title>
      </Modal.Header>
      <Modal.Body  >
        <div className="text-center mb-4">
          <img
            src={user?.image ? `${BaseUrlProfile}/profile/${user?.image}` : ProfilePic}
            alt="Profile"
            className="rounded-circle mb-3"
            width="100"
          />
          <h5>{user?.name}</h5>
        </div>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="mobile">Mobile</Label>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="pinCode">Pin Code</Label>
                <Input
                  type="number"
                  name="pinCode"
                  id="pinCode"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="contact">Contact</Label>
                <Input
                  type="text"
                  name="contact"
                  id="contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="restaurantsName">Restaurant's Name</Label>
                <Input
                  type="text"
                  name="restaurantsName"
                  id="restaurantsName"
                  value={restaurantsName}
                  onChange={(e) => setRestaurantsName(e.target.value)}
                />
              </FormGroup>
             
              <FormGroup>
                <Label for="industry">Industry</Label>
                <Input
                  type="text"
                  name="industry"
                  id="industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="webUrl">Website URL</Label>
                <Input
                  type="url"
                  name="webUrl"
                  id="webUrl"
                  value={webUrl}
                  onChange={(e) => setWebUrl(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="empNumber">Employee Number</Label>
                <Input
                  type="text"
                  name="empNumber"
                  id="empNumber"
                  value={empNumber}
                  onChange={(e) => setEmpNumber(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="location">Location</Label>
                <Input
                  type="text"
                  name="location"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
            <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>

          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>Close</Button>
        <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuickEditsPopup;
