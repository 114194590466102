import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  Button,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCatalogues,
  updateCatalogue,
  deleteCatalogue,
  addCatalogue,
  addSubcategory,
  updateSubcategory,
  deleteSubcategory,
  addItem,
  updateItem,
  deleteItem,
} from "../../store/Catalogue/catalogueSlice";
import { setLoading } from "../../store/Loading/loadingSlice";

const IndexCategories = () => {
  const dispatch = useDispatch();
  const catalogues = useSelector((state) => state.catalogue.catalogues);
  const status = useSelector((state) => state.catalogue.status);
  const [activeKey, setActiveKey] = useState(null);
  const [subcategoryKey, setSubcategoryKey] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [categoryData, setCategoryData] = useState({ title: "" });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newCatalogueData, setNewCatalogueData] = useState({ title: "" });
  const [showAddSubcategoryModal, setShowAddSubcategoryModal] = useState(false);
  const [showEditSubcategoryModal, setShowEditSubcategoryModal] = useState(
    false
  );
  const [subcategoryData, setSubcategoryData] = useState({ title: "" });
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [itemData, setItemData] = useState({ name: "" });
  const [editingItem, setEditingItem] = useState(null);
  const [currentCatalogueId, setCurrentCatalogueId] = useState(null);
  const [currentSubcategoryId, setCurrentSubcategoryId] = useState(null);

  useEffect(() => {
    if (status === "idle") {
    dispatch(setLoading(true));
      dispatch(fetchCatalogues());
    }
  }, [dispatch, status]);

  const handleShowEditModal = (category) => {
    setEditingCategory(category);
    setCategoryData({ title: category.title });
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => setShowEditModal(false);

  const handleSave = () => {
    if (editingCategory) {
      dispatch(
        updateCatalogue({
          id: editingCategory._id,
          updatedCatalogue: { ...editingCategory, ...categoryData },
        })
      );
    }
    handleCloseEditModal();
  };

  const handleDelete = (category) => dispatch(deleteCatalogue(category._id));

  const handleShowAddModal = () => {
    setNewCatalogueData({ title: "" });
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => setShowAddModal(false);

  const handleAddCatalogue = () => {
    dispatch(addCatalogue(newCatalogueData));
    handleCloseAddModal();
  };

  const handleShowAddSubcategoryModal = (catalogueId) => {
    setCurrentCatalogueId(catalogueId);
    setSubcategoryData({ title: "" });
    setShowAddSubcategoryModal(true);
  };

  const handleCloseAddSubcategoryModal = () =>
    setShowAddSubcategoryModal(false);

  const handleAddSubcategory = () => {
    dispatch(
      addSubcategory({
        catalogueId: currentCatalogueId,
        subcategory: subcategoryData,
      })
    );
    handleCloseAddSubcategoryModal();
  };

  const handleShowEditSubcategoryModal = (subcategory) => {
    setEditingSubcategory(subcategory);
    setSubcategoryData({ title: subcategory.title });
    setShowEditSubcategoryModal(true);
  };

  const handleCloseEditSubcategoryModal = () =>
    setShowEditSubcategoryModal(false);

  const handleSaveSubcategory = () => {
    if (editingSubcategory) {
      dispatch(
        updateSubcategory({
          catalogueId: currentCatalogueId,
          subcategoryId: editingSubcategory._id,
          subcategory: { ...editingSubcategory, ...subcategoryData },
        })
      );
    }
    handleCloseEditSubcategoryModal();
  };

  const handleDeleteSubcategory = (subcategory) => {
    dispatch(
      deleteSubcategory({
        catalogueId: currentCatalogueId,
        subcategoryId: subcategory._id,
      })
    );
  };

  const handleShowAddItemModal = (catalogueId, subcategoryId) => {
    setCurrentCatalogueId(catalogueId);
    setCurrentSubcategoryId(subcategoryId);
    setItemData({ name: "" });
    setShowAddItemModal(true);
  };

  const handleCloseAddItemModal = () => setShowAddItemModal(false);

  const handleAddItem = () => {
    dispatch(
      addItem({
        catalogueId: currentCatalogueId,
        subcategoryId: currentSubcategoryId,
        item: itemData,
      })
    );
    handleCloseAddItemModal();
  };

  const handleShowEditItemModal = (item, catalogueId, subcategoryId) => {
    setEditingItem(item);
    setCurrentCatalogueId(catalogueId);
    setCurrentSubcategoryId(subcategoryId);
    setItemData({ name: item.name });
    setShowEditItemModal(true);
  };

  const handleCloseEditItemModal = () => setShowEditItemModal(false);

  const handleSaveItem = () => {
    if (editingItem) {
      dispatch(
        updateItem({
          catalogueId: currentCatalogueId,
          subcategoryId: currentSubcategoryId,
          itemId: editingItem._id,
          item: { ...editingItem, ...itemData },
        })
      );
    }
    handleCloseEditItemModal();
  };

  const handleDeleteItem = (item) => {
    dispatch(
      deleteItem({
        catalogueId: currentCatalogueId,
        subcategoryId: currentSubcategoryId,
        itemId: item._id,
      })
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 p-4">
          <div className="row rounded">
            <div className="col-md-6 p-0 m-0 text-left">
              <h4 className="card-title text-left mb-4">Categories</h4>
            </div>
            <div className="col-md-6 p-0 m-0 text-end">
              <Button
                className="btn btn-sm defaultBtnColor py-2"
                onClick={handleShowAddModal}
              >
                Add Main Category
              </Button>
            </div>
            <div className="col-md-12 p-0 m-0">
              {catalogues.length > 0 ? (
                <Accordion activeKey={activeKey}>
                  {catalogues.map((catalogue) => (
                    <Card key={catalogue._id}>
                      <Card.Header className="d-flex justify-content-between align-items-center bg-white">
                        <div className="w-100">
                          <Accordion.Button
                            as={Button}
                            className="mainCatBtn"
                            variant="link"
                            eventKey={catalogue._id}
                            onClick={() =>
                              setActiveKey(
                                activeKey === catalogue._id
                                  ? null
                                  : catalogue._id
                              )
                            }
                          >
                            {catalogue.title}
                          </Accordion.Button>
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            className="normalBtn btn-sm py-2"
                            id={`dropdown-basic-main-${catalogue._id}`}
                          >
                            Action
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleShowEditModal(catalogue)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDelete(catalogue)}
                            >
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handleShowAddSubcategoryModal(catalogue._id)
                              }
                            >
                              Add Subcategory
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Card.Header>
                      <Accordion.Collapse eventKey={catalogue._id}>
                        <Card.Body>
                          <Accordion activeKey={subcategoryKey}>
                            {catalogue.subcategories.length ? (
                              catalogue.subcategories.map((subcategory) => (
                                <Card key={subcategory._id}>
                                  <Card.Header className="d-flex justify-content-between align-items-center bg-white">
                                    <div className="w-100">
                                      <Accordion.Button
                                        as={Button}
                                        variant="link"
                                        className="mainCatBtn"
                                        eventKey={subcategory._id}
                                        onClick={() =>
                                          setSubcategoryKey(
                                            subcategoryKey === subcategory._id
                                              ? null
                                              : subcategory._id
                                          )
                                        }
                                      >
                                        {subcategory.title}
                                      </Accordion.Button>
                                    </div>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="link"
                                        className="normalBtn btn-sm py-2"
                                        id={`dropdown-basic-sub-${subcategory._id}`}
                                      >
                                        Action
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleShowEditSubcategoryModal(
                                              subcategory
                                            )
                                          }
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDeleteSubcategory(subcategory)
                                          }
                                        >
                                          Delete
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleShowAddItemModal(
                                              catalogue._id,
                                              subcategory._id
                                            )
                                          }
                                        >
                                          Add SubSubCategory
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Card.Header>
                                  <Accordion.Collapse
                                    eventKey={subcategory._id}
                                  >
                                    <Card.Body>
                                        {subcategory.items.length ? (
                                          subcategory.items.map((item) => (
                                            <Card key={subcategory._id}>
                                              <Card.Header className="d-flex justify-content-between align-items-center  bg-white">
                                                <div className="w-100 d-flex">
                                                  <p className="w-50 mb-0">{item.name}</p>
                                                    
                                                    <div className="w-50 sub-sub-categories-btn-div">
                                                    <Button
                                                      className="btn btn-sm btn-primary"
                                                      onClick={() =>
                                                        handleShowEditItemModal(
                                                          item,
                                                          catalogue._id,
                                                          subcategory._id
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </Button>
                                                    <Button
                                                      className="btn btn-sm btn-danger ml-2"
                                                      onClick={() =>
                                                        handleDeleteItem(item)
                                                      }
                                                    >
                                                      Delete
                                                    </Button>
                                                    </div>
                                                </div>
                                              </Card.Header>
                                            </Card>
                                          ))
                                        ) : (
                                          <div className="text-center">
                                            <p className="mb-0">
                                              No SubSubCategory Found
                                            </p>
                                          </div>
                                        )}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))
                            ) : (
                              <div className="text-center">
                                <p className="mb-0">No SubCategory Found</p>
                              </div>
                            )}
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              ) : (
                <div className="card mt-3">
                  <div className="px-4 py-2 text-center">
                    <p className="m-5">No Record Found.</p>
                  </div>
                </div>
              )}

              {/* Edit Catalogue Modal */}
              <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Main Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formCatalogueTitle">
                      <Form.Label className="pl-0">Main Catgeory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={categoryData.title}
                        onChange={(e) =>
                          setCategoryData({
                            ...categoryData,
                            title: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseEditModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSave}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Add Catalogue Modal */}
              <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Main Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formCatalogueTitle">
                      <Form.Label className="pl-0">Main Catgeory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={newCatalogueData.title}
                        onChange={(e) =>
                          setNewCatalogueData({
                            ...newCatalogueData,
                            title: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAddModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleAddCatalogue}>
                    Add Category
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Add Subcategory Modal */}
              <Modal
                show={showAddSubcategoryModal}
                onHide={handleCloseAddSubcategoryModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Subcategory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="formSubcategoryTitle"
                    >
                      <Form.Label className="pl-0">Subcategory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={subcategoryData.title}
                        onChange={(e) =>
                          setSubcategoryData({
                            ...subcategoryData,
                            title: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseAddSubcategoryModal}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleAddSubcategory}>
                    Add Subcategory
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Edit Subcategory Modal */}
              <Modal
                show={showEditSubcategoryModal}
                onHide={handleCloseEditSubcategoryModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Subcategory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="formSubcategoryTitle"
                    >
                      <Form.Label className="pl-0">Subcategory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={subcategoryData.title}
                        onChange={(e) =>
                          setSubcategoryData({
                            ...subcategoryData,
                            title: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseEditSubcategoryModal}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSaveSubcategory}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Add Item Modal */}
              <Modal show={showAddItemModal} onHide={handleCloseAddItemModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Add SubSubCategory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formItemName">
                      <Form.Label className="pl-0">SubSubcategory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={itemData.name}
                        onChange={(e) =>
                          setItemData({ ...itemData, name: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAddItemModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleAddItem}>
                    Add SubSubCategory
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Edit Item Modal */}
              <Modal show={showEditItemModal} onHide={handleCloseEditItemModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit SubSubCategory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formItemName">
                      <Form.Label className="pl-0">Subcategory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={itemData.name}
                        onChange={(e) =>
                          setItemData({ ...itemData, name: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseEditItemModal}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSaveItem}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexCategories;
