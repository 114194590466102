import Swal from 'sweetalert2';

export const showToast = (message, type = 'info') => {
  Swal.fire({
    position: 'bottom-end',
    icon: type, // 'success', 'error', 'warning', 'info'
    title: message,
    showConfirmButton: false,
    timer: 1500,
    toast: true,
  });
};

// Example usage
