import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const BaseUrl = process.env.REACT_APP_BASH_URL;

// API base URL
const API_URL = `${BaseUrl}/admin/membership-plans`;

// Async Thunks

// Create a new membership plan
export const createMembershipPlan = createAsyncThunk(
    'membership/createMembershipPlan',
    async (membershipData, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.post(`${API_URL}/create`, membershipData, { headers: options });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Fetch all membership plans
export const fetchMembershipPlans = createAsyncThunk(
    'membership/fetchMembershipPlans',
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.get(`${API_URL}`, { headers: options });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Fetch a single membership plan by ID
export const fetchMembershipPlanById = createAsyncThunk(
    'membership/fetchMembershipPlanById',
    async (id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.get(`${API_URL}/${id}`, { headers: options });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Update a membership plan by ID
export const updateMembershipPlan = createAsyncThunk(
    'membership/updateMembershipPlan',
    async ({ id, formData }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            console.log('formData : ' , formData)
            const response = await axios.post(`${API_URL}/${id}`, formData , { headers: options });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message || error.message);
        }
    }
);

// Delete a membership plan by ID
export const deleteMembershipPlan = createAsyncThunk(
    'membership/deleteMembershipPlan',
    async (id, { rejectWithValue,dispatch }) => {
        try {
            const token = localStorage.getItem("admin-auth-token");
            const options = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.delete(`${API_URL}/${id}` , { headers: options });
            dispatch(fetchMembershipPlans());
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Slice

const membershipSlice = createSlice({
    name: 'memberships',
    initialState: {
        membershipPlans: [],
        selectedMembershipPlan: null,
        loading: false,
        error: null,
        sortBy: 'name',
        sortOrder: 'asc',
        currentPage: 1,
        itemsPerPage: 10,
    },
    reducers: {
        clearSelectedMembershipPlan: (state) => {
            state.selectedMembershipPlan = null;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortOrder: (state, action) => {
            state.sortOrder = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Create Membership Plan
            .addCase(createMembershipPlan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createMembershipPlan.fulfilled, (state, action) => {
                state.loading = false;
                // state.membershipPlans.push(action.payload.membershipPlan);
            })
            .addCase(createMembershipPlan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Fetch all Membership Plans
            .addCase(fetchMembershipPlans.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMembershipPlans.fulfilled, (state, action) => {
                state.loading = false;
                state.membershipPlans = action.payload;
            })
            .addCase(fetchMembershipPlans.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Fetch Membership Plan by ID
            .addCase(fetchMembershipPlanById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMembershipPlanById.fulfilled, (state, action) => {
                state.loading = false;
                state.selectedMembershipPlan = action.payload;
            })
            .addCase(fetchMembershipPlanById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Update Membership Plan
            .addCase(updateMembershipPlan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMembershipPlan.fulfilled, (state, action) => {
                state.loading = false;
               
            })
            .addCase(updateMembershipPlan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete Membership Plan
            .addCase(deleteMembershipPlan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteMembershipPlan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions and reducer
export const { clearSelectedMembershipPlan,setSortBy, setSortOrder, setCurrentPage } = membershipSlice.actions;
export default membershipSlice.reducer;
