import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setLoading } from '../Loading/loadingSlice';
const BaseUrl = process.env.REACT_APP_BASH_URL;

// API base URL
const API_URL = `${BaseUrl}/admin/subscriptions`;
// Async thunk to fetch subscriptions
export const fetchSubscriptions = createAsyncThunk(
  'subscriptions/fetchSubscriptions',
  async (_,{dispatch}) => {
    const response = await axios.get(`${API_URL}`);
    dispatch(setLoading(false));
    return response.data;
  }
);

const transactionSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    subscriptions: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subscriptions = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default transactionSlice.reducer;
