import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/routes';
function App() {
  return (
   <AppRoutes />
);
}

export default App;
