import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import ProfilePic from "../assets/profile/dummy.jpg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardData } from "../store/Dashboard/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { timeAgo } from "../Helper/functions";
import CreateMemberModal from "../popups/createMemberModal";
import IndexCategories from "./Categories/indexCategories";
import Categories from "./common/Categories";
const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;
const DashBoard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const handleSubmit = (formData) => {
    console.log("Form Data Submitted: ", formData);
    // Handle form submission here, e.g., dispatch an action or make an API call
  };

  const dashbaordData = useSelector((state) => state.dashboard.data);
  const loading = useSelector((state) => state.loading);
  useEffect(() => {
    dispatch(dashboardData());
    console.log("called");
  }, []);

  useEffect(() => {
    console.log("dashbaordData : ", dashbaordData);
  }, [dashbaordData]);
  return (
    <div className="row ">
      <div className="col-lg-8 col-md-9 col-sm-12 p-4">
        <div className="row bg-white border rounded">
          <div className="col-md-12 p-3 border-bottom">
            <div>
              <span className="h5">My Website Resources</span>
            </div>
          </div>
          <div className="col-md-12 p-4">
            <Row>
              <Col lg="6" sm="6" className="mb-4">
                <Card className="border-success overflow-hidden">
                  <CardBody className="d-flex text-left flex-column card-body py-2 card-body">
                    <h5 className="card-title">Vendor Count</h5>
                    <p className="display-6 text-success font-weight-bold mb-0">
                      {dashbaordData.vendorCount ?? 0}
                    </p>
                  </CardBody>
                  <div className="vendor-icon">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 256 256"
                      color="white"
                      height="45"
                      width="45"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "white" }}
                    >
                      <path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path>
                    </svg>
                  </div>
                </Card>
              </Col>
              <Col lg="6" sm="6" className="mb-4">
                <Card className="border-success overflow-hidden">
                  <CardBody className="d-flex text-left flex-column card-body py-2 card-body">
                    <h5 className="card-title">Employee Count</h5>
                    <p className="display-6 text-success font-weight-bold mb-0">
                      {dashbaordData.empCount ?? 0}
                    </p>
                  </CardBody>
                  <div className="vendor-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus"
                      viewBox="0 0 16 16"
                      style={{ color: "white" }}
                    >
                      <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                      />
                    </svg>
                  </div>
                </Card>
              </Col>
              <Col lg="6" sm="6" className="mb-4  ">
                <Card className="border-success overflow-hidden">
                  <CardBody className="d-flex text-left flex-column card-body py-2 card-body">
                    <h5 className="card-title">Job Count</h5>
                    <p className="display-6 text-success font-weight-bold mb-0">
                      {dashbaordData.jobCount ?? 0}
                    </p>
                  </CardBody>
                  <div className="vendor-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-briefcase"
                      viewBox="0 0 16 16"
                      style={{ color: "white" }}
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </div>
                </Card>
              </Col>
              <Col lg="6" sm="6" className="mb-4 ">
                <Card className="border-success overflow-hidden">
                  <CardBody className="d-flex text-left flex-column card-body py-2 card-body">
                    <h5 className="card-title">Api Keys</h5>
                    <p className="display-6 text-success font-weight-bold mb-0">
                      {dashbaordData.apiCount ?? 0}
                    </p>
                  </CardBody>
                  <div className="vendor-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-key"
                      viewBox="0 0 16 16"
                      style={{ color: "white" }}
                    >
                      <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8m4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5" />
                      <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <div className="row bg-white border rounded mt-4">
          <div className="col-lg-12 col-md-9 col-sm-12 p-4 border-bottom">
            <div>
              <span className="h5">Member Database</span>
            </div>
          </div>
          <div className="col-md-12 p-4">
            <Row>
              <Col lg="6" sm="6" className=" members-section border-end">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/members");
                  }}
                >
                  <div className="icon-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                  </div>
                  <p className="">View Members</p>
                </div>
              </Col>
              <Col lg="6" sm="6" className=" members-section">
                <div>
                  <div
                    className="icon-wrapper cursor-pointer"
                    onClick={toggleModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                    </svg>
                  </div>
                  <div>
                    <p className="">Add Members</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-9 col-sm-12 p-4 member-database">
        <div className="row bg-white border rounded ">
          <div className="col-md-12 p-3 border-bottom mb-3">
            <div>
              <span className="h5">Member Database</span>
            </div>
          </div>
          <div className="scrolldiv">
            {dashbaordData?.loginLogs?.length ? (
              dashbaordData.loginLogs.map((log) => (
                <div key={log._id} className="col-md-12 px-4 py-2">
                  <div className="row mb-3">
                    <div className="col-sm-3 d-flex align-items-center">
                      <img
                        src={
                          log.userId?.image
                            ? `${BaseUrlProfile}/profile/${log.userId?.image}`
                            : ProfilePic
                        } // Use default image if profilePic is not available
                        alt="Member"
                        className="rounded-circle"
                        width="60"
                        height="60"
                      />
                    </div>
                    <div className="col-sm-7">
                      <p className="mb-1">
                        Login: <span>{timeAgo(log.loginTime)}</span>
                      </p>
                      <p className="mb-1">
                        User: <span>{log.userId?.name || "Unknown User"}</span>
                      </p>
                      <Button color="success" className="deleteBtn px-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                        </svg>
                        <span className="pl-2">Delete</span>
                      </Button>
                    </div>
                    <div className="col-sm-1 pt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-three-dots-vertical"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row mb-3">
                <div className="col-sm-12 text-center">
                  <p>No Record Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <CreateMemberModal
        isOpen={modal}
        toggle={toggleModal}
        onSubmit={handleSubmit}
      />
      <div className="col-lg-12 p-4">
        <Categories/>
      </div>

    </div>
  );
};

export default DashBoard;
