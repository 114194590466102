import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchUserProfile, updateUserProfile } from "../store/Auth/authSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);

  const [profileData, setProfileData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    profilePic: null,
  });

  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log('user : ', user);
  }, [user]);



  useEffect(() => {
    if (user) {
      setProfileData({
        firstName: user.firstName || "",
        middleName: user.middleName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        password: "", // Password is usually not fetched for security reasons
        profilePic: user.profilePic || null,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "profilePic") {
      setProfileData((prevState) => ({
        ...prevState,
        profilePic: files[0],
      }));
    } else {
      setProfileData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstName", profileData.firstName);
    formData.append("middleName", profileData.middleName);
    formData.append("lastName", profileData.lastName);
    formData.append("email", profileData.email);
    if (profileData.password) {
      formData.append("password", profileData.password);
    }
    if (profileData.profilePic) {
      formData.append("profilePic", profileData.profilePic);
    }

    dispatch(updateUserProfile(formData));
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <div className="row rounded">
          <div className="col-md-12 p-0 m-0">
            <h4 className="card-title text-left mb-3">Profile</h4>

            <div className="card">
              <div className="card-body p-5">
                <form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={profileData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="middleName" className="form-label">
                        Middle Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="middleName"
                        value={profileData.middleName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={profileData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email ID
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        autoComplete="off"
                        value={profileData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="password"
                        autoComplete="off"
                        value={profileData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="profilePic" className="form-label">
                        Upload Profile Pic
                      </label>
                      <div className="upload-box" onClick={handleFileClick}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          width="20px"
                        >
                          <path d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
                        </svg>
                        <input
                          type="file"
                          className="form-control-file"
                          id="profilePic"
                          style={{ visibility: "hidden" }}
                          ref={fileInputRef}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      className="btn btn-success px-4"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
