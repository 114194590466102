import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from './Dashboard/dashboardSlice';
import membersReducer from './Members/membersSlice';
import authReducer from './Auth/authSlice';
import loadingReducer from './Loading/loadingSlice';
import membershipReducer from './Membership/membershipSlice';
import CategoryReducer from './Category/categoriesSlice';
import catalogueReducer from './Catalogue/catalogueSlice';
import transactionReducer from './Transactions/transactionSlice';
import notificationReducer from './Notification/userNotificationsSlice';
import trackerReducer from './Members/trackerSlice';
import documentReducer from './Product/documentSlice';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  memberships: membershipReducer,
  members: membersReducer,
  categories: CategoryReducer,
  catalogue: catalogueReducer,
  auth: authReducer,
  loading: loadingReducer,
  notifications: notificationReducer,
  transaction: transactionReducer,
  timeTracker: trackerReducer,
  documents: documentReducer,

});

export default rootReducer;
