import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setLoading } from '../Loading/loadingSlice';

const BaseUrl = process.env.REACT_APP_BASH_URL;
const API_URL = `${BaseUrl}/admin/catalogues`;

// Initial state
const initialState = {
    catalogues: [],
    status: 'idle',
    error: null,
};

// Thunks
export const fetchCatalogues = createAsyncThunk('catalogues/fetchCatalogues', async (_,{dispatch}) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.get(`${API_URL}`, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(setLoading(false));

    return response.data;
});

export const addCatalogue = createAsyncThunk('catalogues/addCatalogue', async (newCatalogue, { dispatch }) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.post(`${API_URL}`, newCatalogue, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(fetchCatalogues());

    return response.data;
});

export const updateCatalogue = createAsyncThunk('catalogues/updateCatalogue', async ({ id, updatedCatalogue }, { dispatch }) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.put(`${API_URL}/${id}`, updatedCatalogue, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(fetchCatalogues());

    return response.data;
});

export const deleteCatalogue = createAsyncThunk('catalogues/deleteCatalogue', async (id) => {
    const token = localStorage.getItem("admin-auth-token");
    await axios.delete(`${API_URL}/${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return id;
});

export const addSubcategory = createAsyncThunk('catalogues/addSubcategory', async ({ catalogueId, subcategory }) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.post(`${API_URL}/${catalogueId}/subcategories`, subcategory, { headers: { Authorization: `Bearer ${token}` } });
    return { catalogueId, subcategory: response.data };
});

export const updateSubcategory = createAsyncThunk('catalogues/updateSubcategory', async ({ catalogueId, subcategoryId, subcategory }, { dispatch }) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.put(`${API_URL}/${catalogueId}/subcategories/${subcategoryId}`, subcategory, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(fetchCatalogues());
     return { catalogueId, subcategoryId, subcategory: response.data };
});

export const deleteSubcategory = createAsyncThunk('catalogues/deleteSubcategory', async ({ catalogueId, subcategoryId }) => {
    const token = localStorage.getItem("admin-auth-token");
    await axios.delete(`${API_URL}/${catalogueId}/subcategories/${subcategoryId}`, { headers: { Authorization: `Bearer ${token}` } });
    return { catalogueId, subcategoryId };
});

export const addItem = createAsyncThunk('catalogues/addItem', async ({ catalogueId, subcategoryId, item }) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.post(`${API_URL}/${catalogueId}/subcategories/${subcategoryId}/items`, item, { headers: { Authorization: `Bearer ${token}` } });
    return { catalogueId, subcategoryId, item: response.data };
});

export const updateItem = createAsyncThunk('catalogues/updateItem', async ({ catalogueId, subcategoryId, itemId, item }, { dispatch }) => {
    const token = localStorage.getItem("admin-auth-token");
    const response = await axios.put(`${API_URL}/${catalogueId}/subcategories/${subcategoryId}/items/${itemId}`, item, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(fetchCatalogues());
    return { catalogueId, subcategoryId, itemId, item: response.data };
});

export const deleteItem = createAsyncThunk('catalogues/deleteItem', async ({ catalogueId, subcategoryId, itemId }) => {
    const token = localStorage.getItem("admin-auth-token");
    await axios.delete(`${API_URL}/${catalogueId}/subcategories/${subcategoryId}/items/${itemId}`, { headers: { Authorization: `Bearer ${token}` } });
    return { catalogueId, subcategoryId, itemId };
});

// Slice
const catalogueSlice = createSlice({
    name: 'catalogues',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCatalogues.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCatalogues.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.catalogues = action.payload;
            })
            .addCase(fetchCatalogues.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addCatalogue.fulfilled, (state, action) => {
                state.catalogues.push(action.payload);
            })
            // .addCase(updateCatalogue.fulfilled, (state, action) => {
            //     const index = state.catalogues.findIndex(catalogue => catalogue._id === action.payload._id);
            //     if (index !== -1) {
            //         state.catalogues[index] = action.payload;
            //     }
            // })
            .addCase(deleteCatalogue.fulfilled, (state, action) => {
                state.catalogues = state.catalogues.filter(catalogue => catalogue._id !== action.payload);
            })
            .addCase(addSubcategory.fulfilled, (state, action) => {
                const catalogue = state.catalogues.find(catalogue => catalogue._id === action.payload.catalogueId);
                if (catalogue) {
                    catalogue.subcategories.push(action.payload.subcategory);
                }
            })
            // .addCase(updateSubcategory.fulfilled, (state, action) => {
            //     const catalogue = state.catalogues.find(catalogue => catalogue._id === action.payload.catalogueId);
            //     if (catalogue) {
            //         const index = catalogue.subcategories.findIndex(subcategory => subcategory._id === action.payload.subcategoryId);
            //         if (index !== -1) {
            //             catalogue.subcategories[index] = action.payload.subcategory;
            //         }
            //     }
            // })
            .addCase(deleteSubcategory.fulfilled, (state, action) => {
                const catalogue = state.catalogues.find(catalogue => catalogue._id === action.payload.catalogueId);
                if (catalogue) {
                    catalogue.subcategories = catalogue.subcategories.filter(subcategory => subcategory._id !== action.payload.subcategoryId);
                }
            })
            .addCase(addItem.fulfilled, (state, action) => {
                const catalogue = state.catalogues.find(catalogue => catalogue._id === action.payload.catalogueId);
                if (catalogue) {
                    const subcategory = catalogue.subcategories.find(subcategory => subcategory._id === action.payload.subcategoryId);
                    if (subcategory) {
                        subcategory.items.push(action.payload.item);
                    }
                }
            })
            // .addCase(updateItem.fulfilled, (state, action) => {
            //     const catalogue = state.catalogues.find(catalogue => catalogue._id === action.payload.catalogueId);
            //     if (catalogue) {
            //         const subcategory = catalogue.subcategories.find(subcategory => subcategory._id === action.payload.subcategoryId);
            //         if (subcategory) {
            //             const index = subcategory.items.findIndex(item => item._id === action.payload.itemId);
            //             if (index !== -1) {
            //                 subcategory.items[index] = action.payload.item;
            //             }
            //         }
            //     }
            // })
            .addCase(deleteItem.fulfilled, (state, action) => {
                const catalogue = state.catalogues.find(catalogue => catalogue._id === action.payload.catalogueId);
                if (catalogue) {
                    const subcategory = catalogue.subcategories.find(subcategory => subcategory._id === action.payload.subcategoryId);
                    if (subcategory) {
                        subcategory.items = subcategory.items.filter(item => item._id !== action.payload.itemId);
                    }
                }
            });
    },
});

export default catalogueSlice.reducer;
