import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'reactstrap';
import ProfilePic from "../assets/profile/dummy.jpg"; // Path to default profile image

const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;

const ProfileViewPopup = ({ isOpen, toggle, user }) => {
  const profile = user?.profile || {};

  return (
    <Modal show={isOpen} onHide={toggle} centered size="lg" className="custom-modal custom-view-modal">
      <Modal.Header closeButton>
        <Modal.Title>Profile Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-4">
          <img
            src={user?.image ? `${BaseUrlProfile}/profile/${user?.image}` : ProfilePic}
            alt="Profile"
            className="rounded-circle mb-3"
            width="150"
            height="150"
          />
          <h5>{user?.name}</h5>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th>Email</th>
                <td>{user?.email}</td>
              </tr>
              <tr>
                <th>Mobile</th>
                <td>{profile.mobile}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{profile.address}</td>
              </tr>
              <tr>
                <th>Pin Code</th>
                <td>{profile.pinCode}</td>
              </tr>
              <tr>
                <th>Contact</th>
                <td>{profile.contact}</td>
              </tr>
              <tr>
                <th>Restaurant's Name</th>
                <td>{profile.restaurantsName}</td>
              </tr>
              <tr>
                <th>Industry</th>
                <td>{profile.industry}</td>
              </tr>
              <tr>
                <th>Website URL</th>
                <td>
                  <a href={profile.webUrl} target="_blank" rel="noopener noreferrer">{profile.webUrl}</a>
                </td>
              </tr>
              <tr>
                <th>Employee Number</th>
                <td>{profile.empNumber}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>{profile.location}</td>
              </tr>
              <tr>
                <th>Year</th>
                <td>{profile.year}</td>
              </tr>
              <tr>
                <th>Description</th>
                <td>{profile.description}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileViewPopup;
