import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../Pages/login';
import Dashboard from '../Components/dashBoard'; // Import your Dashboard component
import AuthenticatedLayout from '../layout/Auth/AuthenticatedLayout'; // Import your layout
import Members from '../Components/members';
import Create from '../Components/Subscriptions/create';
import Index from '../Components/Subscriptions';
import Edit from '../Components/Subscriptions/edit';
import CreateCategories from '../Components/Categories/createCategories';
import IndexCategories from '../Components/Categories/indexCategories';
import EditCategories from '../Components/Categories/editCategories';
import RevenueTransaction from '../Components/Transactions/revenueTransaction';
import Profile from '../Components/profile';
import NotificationsList from '../Components/notificationsList';
import TimeTracker from '../Components/timeTracker';
import DocumentManage from '../Components/Product/documentManage';

// PrivateRoute component
const PrivateRoute = ({ element, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem('admin-auth-token');
    return isAuthenticated ? element : <Navigate to="/login" />;
};

// PublicRoute component
const PublicRoute = ({ element, restricted, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem('admin-auth-token');
    return !isAuthenticated || !restricted ? element : <Navigate to="/dashboard" />;
};

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<PublicRoute element={<Login />} restricted={true} />} />
            <Route path="/" element={<PublicRoute element={<Login />} restricted={true} />} />

            <Route path="/dashboard" element={<PrivateRoute element={<AuthenticatedLayout><Dashboard /></AuthenticatedLayout>} />} />
            <Route path="/members" element={<PrivateRoute element={<AuthenticatedLayout><Members /></AuthenticatedLayout>} />} />
            <Route path="/members/timeTracker" element={<PrivateRoute element={<AuthenticatedLayout><TimeTracker /></AuthenticatedLayout>} />} />
            <Route path="/notifications" element={<PrivateRoute element={<AuthenticatedLayout><NotificationsList /></AuthenticatedLayout>} />} />
            <Route path="/transactions" element={<PrivateRoute element={<AuthenticatedLayout><RevenueTransaction /></AuthenticatedLayout>} />} />
            <Route path="/subscriptions" element={<PrivateRoute element={<AuthenticatedLayout><Index /></AuthenticatedLayout>} />} />
            <Route path="/subscriptions/create" element={<PrivateRoute element={<AuthenticatedLayout><Create /></AuthenticatedLayout>} />} />
            <Route path="/subscriptions/:id" element={<PrivateRoute element={<AuthenticatedLayout><Edit /></AuthenticatedLayout>} />} />

            <Route path="/categories" element={<PrivateRoute element={<AuthenticatedLayout><IndexCategories /></AuthenticatedLayout>} />} />
            <Route path="/categories/create" element={<PrivateRoute element={<AuthenticatedLayout><CreateCategories /></AuthenticatedLayout>} />} />
            <Route path="/categories/:id" element={<PrivateRoute element={<AuthenticatedLayout><EditCategories /></AuthenticatedLayout>} />} />
            <Route path="/profile" element={<PrivateRoute element={<AuthenticatedLayout><Profile /></AuthenticatedLayout>} />} />


            <Route path="/product/document-manage" element={<PrivateRoute element={<AuthenticatedLayout><DocumentManage /></AuthenticatedLayout>} />} />

            {/* Add more private routes as needed */}
        </Routes>
    );
};

export default AppRoutes;
